import React from 'react';
import {Box, Grid, Hidden} from '@mui/material';

//offers.offers.sort((a,b) => parseFloat(a.payout) - parseFloat(b.payout))[0]

function Sidebar({id}) {
    //const offers = useGrip()
    //const offerx = offers?.offers?.sort((a,b) => parseFloat(b.payout) - parseFloat(a.payout)).filter((offer,index)=>(index+10)%2===id)

    return (
        <Grid item xs={12} md={3}>
            <Box my={4} style={{ position: 'sticky', top: '0px' }}>
                
                    {/*<Button component={Link} href="https://new.boxingstreams100.com/">
                        <Box>
                            <Avatar
                                variant={"square"} src="/img/tyson-fury-vs-dillian-whyte.png"
                                sx={{width: '100%', height: 'auto', borderRadius: '15px'}}
                            />
                            <Typography component={Box} my={2} variant='h5'>Boxing Streams 100</Typography>
                        </Box>
                    </Button>*/}

               {/* <Box my={2} sx={{display:"flex",justifyContent:'center'}} >{id === 0 ? <a href="https://bit.ly/39iWuhO" target="_top" id="1159290">
                        <img src="https://a.impactradius-go.com/display-ad/5618-1159290" border="0" alt="" width="100%"/>
                    </a> */}
                {           ///*<a href="https://bit.ly/3QglMxW" target="_blank"><img src="https://media.go2speed.org/brand/files/nordvpn/15/300x250v12.gif" width="300" height="250" border="0" /></a>*/
                }                {/* : <a href="https://bit.ly/3NEWOGR" target="_top" id="1187124">
                          <img src="https://a.impactradius-go.com/display-ad/5618-1187124" border="0" alt="" width="100%"/>
                        </a>
                }</Box>*/}
                    {/* <AdUI format="horizontal" />
                    <AdUI format="rectangle" /> */}
                {id === 0 ? <div align="center" id="div-gpt-ad-8176806-1"></div>: <div align="center" id="div-gpt-ad-8176806-2"></div>}
                <br/><br/>
                    <Hidden only={['xs', 'sm']}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2, pb: 0 }}>
                            <a href='https://s.click.aliexpress.com/e/_onQJUke?bz=160*600' target='_parent'><img width='160' height='600' src='https://ae01.alicdn.com/kf/S9ab299b905c6418e8a3637d25fe88321c.png' /></a>
                        </Box>
                        {/*<AdUI format="horizontal"/>
                            <AdUI format="vertical"  />*/}
                        {id === 0 ? <>
                            <div align="center" id="div-gpt-ad-8176806-6"></div>
                            {/*<Box my={2} sx={{display:"flex",justifyContent:'center'}}>
                                <a href="https://live1.boxingstreams100.com/fight/3323/-anthony-joshua-vs.-oleksandr-usyk-">
                                    <img src="https://d1sgwhnao7452x.cloudfront.net/Landing-Page-Fight-Card-Request---AJ-Usyk.jpg" width="100%" alt=""/>
                                </a>
                            </Box>*/}

                            

                            {/*{offerx?.map((offer)=>(<Box my={2} sx={{display: "flex", justifyContent: 'center'}}>
                                <a href={offer.offerlink} target="_top">
                                    <img src={offer.offerphoto} border="0" alt="" width="100%"/>
                                </a>
                            </Box>))}*/}

                            {/*<ADSide1/>
                            <div align="center" id="div-gpt-ad-8176806-1"></div>
                            <div align="center" id="div-gpt-ad-8176806-6"></div>*/}
                        </> : <>
                            <div align="center" id="div-gpt-ad-8176806-3"></div>
                           {/* <Box my={2} sx={{display:"flex",justifyContent:'center'}}>
                                <a href="https://ufc.mmastreams100.com/fight/ufc/600021776/ufc-278:-usman-vs.-edwards-2">
                                    <img src="https://s.secure.espncdn.com/stitcher/artwork/collections/airings/7c6f15aa-8c2f-4c20-a720-2190cb5b2a72/16x9.jpg" width="100%" alt=""/>
                                </a>
                            </Box>*/}

                         

                            
                        </>
                        }
                    </Hidden>
            </Box>
        </Grid>
    );
}

export default Sidebar;
