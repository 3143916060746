import * as actionTypes from './types';
import axios from 'axios';

const tops = ["367","364","359","382","360","363","86","83","1068","111","103","104","110","114","132","124","160","176"]

// const isProd = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'?'https://new.soccerstreams-100.tv':'');

const API = axios.create({
    baseURL: "https://scdn.monster"
});

export const fetchGames = () => dispatch => {
    API.get('/api/sport/football')
        .then(({ data }) => {

        const gs = data.map(er => er.gs).flat().filter(gs => tops.includes(gs.c[0].i) || tops.includes(gs.c[1].i) ).filter(gs => !gs.s.tp.cm)
        if(gs.length > 0){
            data.unshift({
                d: "20220213",
                gs,
                lgn: "Top Games"
            })
        }

        dispatch({
            type: actionTypes.FETCH_GAMES_SUCCESS,
            payload: data
        })
    }).catch((e) => {
        console.log(navigator.userAgent)
        dispatch({
            type: actionTypes.FETCH_GAMES_ERROR,
            payload: e
        })
    })
};