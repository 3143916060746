import React from 'react';
import {Avatar, Grid} from '@mui/material';
import * as yup from 'yup';

const TeamLogo = ({ src, alt, size }) => {
    return (
        <Grid item>
            <Avatar
                variant='square'
                sx={{
                    width: size ?64:24,
                    height: size ?64:24
            }}
                src={`https://scdn.monster/api/img/soccer/${src}.png${size ? '?w=128&h=128' : '?w=48&h=48'}`}
                alt={alt + ' Soccer Streams'}
            />
        </Grid>
    );
};

TeamLogo.prototype = {
    src: yup.string().required(),
    alt: yup.string().required(),
    size: yup.boolean().required()
};

export default React.memo(TeamLogo);
