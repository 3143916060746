import * as actionTypes from './types';
import axios from 'axios';

//const isProd = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'?'https://new.soccerstreams-100.tv':'')

const API = axios.create({
    baseURL: "https://scdn.monster"
});

export const fetchGame = (id) => (dispatch) => {
    API.get('/api/game/football/'+id).then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_GAME_SUCCESS,
            payload: data
        });
    }).catch((e) => dispatch({
        type: actionTypes.FETCH_GAME_ERROR,
        payload: e
    }))
};

export const destroyGame = () => dispatch => {
    dispatch({
        type: actionTypes.DESTROY_GAME
    });
};