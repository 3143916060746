import {Button, Link as Linker, Tab, Tabs} from '@mui/material';
import React from 'react';
import {useLocation} from "react-router-dom";
import {SET_LIVE} from "../../../../store/main/types";
import {useDispatch, useSelector} from "react-redux";
import MenuItem from "./MenuItem";

//const MenuItem = Loadable(lazy(() => import('./MenuItem')));

export default function Menu({ value }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const { liveOnly } = useSelector(({ main }) => main);

    return <Tabs scrollButtons='auto' value={value} sx={{
        flexGrow: 1,
        '& .MuiTab-root': {
            minWidth: '80px',
            '&.Mui-selected': {
                color: '#4caf50'
            }
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#4caf50'
        }
    }} centered>
        <MenuItem to={'/'} label='Home' />
        <MenuItem to={'/advertise'} label='Advertise' />
        { /* <Tab component={Linker} href={'https://streamer.soccerstreams-100.tv/'} label='Submit' />
        <MenuItem to={'/rules'} label='Rules' />*/}
        <MenuItem to={'/contact'} label='Contact' />
        <MenuItem to={'/dcma'} label='Dcma' />
        <MenuItem to={'/privacy'} label='Privacy' />
        <Tab sx={{display: 'none'}} value={-1} />
        {location.pathname === "/" && <Tab onClick={() => dispatch({type:SET_LIVE,payload:true})} sx={{color:'red'}} value={-2}
                                           label={<Button variant="contained" color={liveOnly?"error":"inherit"}>Live</Button>} />}
    </Tabs>;
}
