import React, {useEffect} from 'react';
import {Card, Grid, Typography, Box} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {styled} from "@mui/material/styles";
import Menu from "./Menu";

const Line = styled(Grid)`
  background-color: #4caf50;
  height: 1px;
  width: 75px;
  flex: 1
`

export default function MainAppBar() {
    let location = useLocation();
    const [value, setValue] = React.useState(0);
    useEffect(() => setValue(['/','/advertise', 'streamer', '/rules', '/contact', '/dcma', '/privacy'].indexOf(location.pathname)), [setValue, location.pathname]);
    return (
        <Card sx={{marginBottom: '32px',borderRadius: '0 !important'}}>
            <Grid item container alignItems='center' sx={{height: '120px'}}>
                <Line item/>
                <Typography variant='h3' component='h1' sx={{padding: '0 40px'}}>Soccer Streams</Typography>
                <Line item/>
            </Grid>

            <Box my={2} sx={{display:"flex",justifyContent:'center',}}>
                <Box as="a" href='https://s.click.aliexpress.com/e/_onQJUke?bz=725*90' target='_top' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2, pb: 0 }}>
                    <img width='725' height='90' src='https://ae01.alicdn.com/kf/S2d7524ba2465483d8a88f9d75c454fdd2.png' />
                </Box>
            </Box>

            {/*<PubFuture id="628ff2e1564893002873b610" />
            <AdUIX format="horizontal"/>*/}
            <Menu value={value} />
        </Card>
    );
}
